export const FirebaseConfig = {
	"projectId": "juniorapp-d45c9",
	"appId": "1:904442889514:web:a1ea9b76bd659023460a53",
	"databaseURL": "https://juniorapp-d45c9-default-rtdb.firebaseio.com",
	"storageBucket": "juniorapp-d45c9.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAKRBP9Z-BINB4xbQlEvg0r7zHnnQwXWpk",
	"authDomain": "juniorapp-d45c9.firebaseapp.com",
	"messagingSenderId": "904442889514",
	"measurementId": "G-GZ8NL6GL1Y"
};